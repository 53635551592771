import React from 'react';
import classnames from 'classnames';
import { Box, type BoxProps } from 'bb/ui/Box';
import { type PaddingProps } from 'bb/ui/Padding';
import { type PolymorphicComponent, type PolymorphicRef } from 'bb/ui/types';
import css from './block.module.scss';

export type BlockProps<TElementType extends React.ElementType = 'div'> =
    PolymorphicComponent<
        TElementType,
        {
            withPagePadding?: boolean;
            alignItems?: 'start' | 'end' | 'center' | 'stretch';
            classNames?: string;
            grow?: boolean;
        } & BoxProps<TElementType> &
            PaddingProps
    >;

const BlockComponent = (
    {
        children,
        withPagePadding,
        alignItems = 'center',
        classNames = '',
        grow = false,
        as = 'div',
        ...restProps
    }: BlockProps<'div'>,
    ref?: PolymorphicRef<'div'>
) => (
    <Box
        ref={ref}
        as={as}
        className={classnames(
            classNames,
            css.block,
            css[`align-${alignItems}`],
            {
                [css.padding as string]: withPagePadding,
                [css.grow as string]: grow
            }
        )}
        {...restProps}
    >
        {children}
    </Box>
);

/**
 * @deprecated Use Box or Section instead.
 */
export const Block = React.forwardRef(BlockComponent) as <
    T extends React.ElementType = 'div'
>(
    props: PolymorphicComponent<T, BlockProps<T>>
) => JSX.Element;
