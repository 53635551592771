import React from 'react';
import { useTranslation } from 'bb/i18n';
import css from './numberOfBooks.module.scss';

export const NUMBER_OF_BOOKS_FALLBACK = 900000;

export const getNumberOfBooks = (numberString: string | undefined) => {
    if (!numberString) return NUMBER_OF_BOOKS_FALLBACK;

    /**
     * Remove all whitespace from the string and parse it as an integer.
     */
    const parsedNumber = parseInt(numberString.replace(/\s/g, ''), 10);

    if (Number.isNaN(parsedNumber)) {
        return NUMBER_OF_BOOKS_FALLBACK;
    }

    return parsedNumber;
};

export const useGetNumberOfBooks = () => {
    const { i18n, t } = useTranslation(['common']);

    return () =>
        new Intl.NumberFormat(i18n.language).format(
            getNumberOfBooks(t('common:numberOfBooks'))
        );
};

export const NumberOfBooks = () => (
    <span className={css.base}>{useGetNumberOfBooks()()}</span>
);
