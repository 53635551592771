import React, { type FC } from 'react';
import { Block } from 'bb/common/components/blocks/block/Block';
import { Grid } from 'bb/common/grid/Grid';
import { Section } from 'bb/common/section';
import { Stack } from 'bb/ui/Flex/Stack';
import { type BlockProps } from '../components/blocks/block/Block';
import css from './splitBlock.module.scss';

export type Props = {
    left: React.ReactNode;
    right: React.ReactNode;
    bgColor?: BlockProps['bgColor'];
};

export const SplitBlock: FC<Props> = ({
    left,
    right,
    bgColor = 'primary-white'
}) => (
    <Section>
        <Grid>
            <Block
                withPagePadding
                paddingBottom="large"
                paddingTop="large"
                bgColor={bgColor}
            >
                <Stack alignItems="center" justifyContent="center">
                    {left}
                </Stack>
            </Block>
            <Block classNames={css.right}>{right}</Block>
        </Grid>
    </Section>
);
