import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSimpleLogger } from './logger/useSimpleLogger';

export const LoggingErrorBoundary: React.FC<{
    children?: React.ReactNode;
    errorName: string;
}> = ({ children, errorName }) => {
    const logger = useSimpleLogger();
    const onError = (error: Error) => {
        const url = `${window.location?.hostname}${window.location?.pathname}`;
        const message = `${errorName} ${error?.name} ${error?.message} ${error?.stack}`;
        logger.error(message, {
            userAgent: navigator?.userAgent,
            loggedAtUrl: url
        });
    };
    return (
        <ErrorBoundary onError={onError} fallback={null}>
            {children}
        </ErrorBoundary>
    );
};
