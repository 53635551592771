import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'bb/app/router';

type KindlyEvent = {
    detail: { type: string };
};

export const useKindlyChat = (intersectionRef: React.RefObject<Element>) => {
    const { router } = useRouter();
    const [isIntersecting, setIsIntersecting] = useState(false);
    /**
     * For some routes we want to always show the bubble.
     */
    const alwaysShowBubble = (path: string) =>
        path.includes('/account/') || path.includes('/welcome');

    /**
     * Sets the start values for the kindly chat which is hidden
     */
    useEffect(() => {
        window.kindlyOptions = {
            bubbleHidden: true
        };
    }, []);

    /**
     * Creates the IntersactionObserver if the kindly chat script has loaded.
     * This is not using the hook useIntersectionObserver because we want to update the state variable in the callback.
     */
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            entry && setIsIntersecting(entry.isIntersecting);
        });
        intersectionRef.current && observer.observe(intersectionRef.current);
        return () => observer.disconnect();
    }, [intersectionRef, router.asPath]);

    /**
     * Adds an eventlistener for if the chat has been opened or closed.
     * This allows us to not hide the chat if the chat is opened.
     * close-chatbubble is only called when a chat is Finished by the use, not when the chat window is closed
     */
    useEffect(() => {
        const handleChatOpenOrClose = (event: Event) => {
            const { type } = (event as unknown as KindlyEvent).detail;
            if (type === 'open-chatbubble') {
                window.kindlyOpened = true;
            } else if (type === 'end-chat') {
                window.kindlyOpened = false;
            }
        };
        window.addEventListener('kindly:ui', handleChatOpenOrClose);
        return () => {
            window.removeEventListener('kindly:ui', handleChatOpenOrClose);
        };
    }, []);

    /**
     * Shows the bubble when the component that has the intersectionRef is visible or if the user is on an always show route.
     * window.kindlyOptions is used on first rendering and then we need showBubble to override hideBubble.
     */
    useEffect(() => {
        if (isIntersecting || alwaysShowBubble(router.asPath)) {
            if (window) {
                window.kindlyOptions = {
                    bubbleHidden: false
                };
                window.kindlyChat?.showBubble?.();
            }
        }
    }, [isIntersecting, router.asPath]);

    /**
     * Tracking the last path visited to fix bug that chat was hidden when pressing a
     * link in the menu several times.
     */
    const lastPath = useRef<string>();

    /**
     * Hides the bubble on reroute if the chat has not been opened,
     * the path has changed and we are not on a always show route.
     */
    useEffect(() => {
        if (router.asPath === lastPath.current) {
            return;
        }
        lastPath.current = router.asPath;
        if (
            !isIntersecting &&
            !alwaysShowBubble(router.asPath) &&
            window &&
            !window.kindlyOpened
        ) {
            window.kindlyChat?.hideBubble();
        }
    }, [isIntersecting, router.asPath]);

    /**
     * Sets setIsIntersecting to false to ensure that the bubble is shown again if
     * the intersectionRef is visable directly on the new page.
     */
    useEffect(() => {
        const resetIsIntersecting = () => {
            setIsIntersecting(false);
        };

        router.events.on('routeChangeStart', resetIsIntersecting);
        return () => {
            router.events.off('routeChangeStart', resetIsIntersecting);
        };
    }, [router]);
};
