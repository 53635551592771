import React, { Children, type FC } from 'react';
import classnames from 'classnames';
import { type Color } from 'bb/style/types';
import { makeCSSVariableFromColor } from 'bb/style/utils';
import { Col } from 'bb/ui';
import css from './grid.module.scss';

type GridProps = {
    children?: React.ReactNode;
    bgColor?: Color;
    className?: string;
};
export const Grid: FC<GridProps> = ({ children, bgColor, className }) => (
    <div className={classnames(css.grid, className)}>
        {Children.map(children, (child: React.ReactNode) => {
            if (!child) return null;
            return (
                <Col
                    style={makeCSSVariableFromColor(
                        '--grid-background-color',
                        bgColor
                    )}
                    className={css.column}
                >
                    {child}
                </Col>
            );
        })}
    </div>
);
