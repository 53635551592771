/* eslint-disable @next/next/no-img-element */
import React, { type FunctionComponent, type ReactElement } from 'react';
import { _electron } from '@playwright/test';
import { Box, Flex, type JustifyContentType, type AlignItems } from 'bb/ui';
import css from './ccImages.module.scss';

type CCImagesProps = {
    cards: Array<string>;
    justifyContent?: JustifyContentType;
    alignItems?: AlignItems;
};

export const CCImages: FunctionComponent<CCImagesProps> = ({
    cards,
    justifyContent = 'center',
    alignItems = 'center'
}): ReactElement => (
    <Box marginTop="large">
        <Flex
            justifyContent={justifyContent}
            alignItems={alignItems}
            gap="small"
        >
            {cards.includes('visa') && (
                <img
                    alt="Visa card logo"
                    className={css.visa}
                    src="/images/cc/visa.png"
                    loading="lazy"
                />
            )}
            {cards.includes('visaElectron') && (
                <img
                    alt="Visa Electron card logo"
                    className={css.visaElectron}
                    src="/images/cc/visa-electron.png"
                    loading="lazy"
                />
            )}
            {cards.includes('mastercard') && (
                <img
                    alt="mastercard logo"
                    className={css.mastercard}
                    src="/images/cc/mastercard.png"
                    loading="lazy"
                />
            )}
            {cards.includes('dankort') && (
                <img
                    alt="danKort logo"
                    className={css.dankort}
                    src="/images/cc/dankort.png"
                    loading="lazy"
                />
            )}
        </Flex>
    </Box>
);
