/* eslint-disable @next/next/no-img-element */
import React from 'react';
import classnames from 'classnames';
import { useRegistrationTracker } from 'bb/tracker/hooks/useRegistrationTracker';
import css from './badges.module.scss';
import { ExternalLink } from './ExternalLink';

export type AppDownloadBadgeColor = 'black' | 'white' | 'default';

export type AppDownloadLink = string | null;

const PLAY_STORE_IMAGES: Record<
    'playStore' | 'appStore',
    Record<AppDownloadBadgeColor, string>
> = {
    playStore: {
        black: '/images/app-download/GooglePlayBlack.svg',
        white: '/images/app-download/GooglePlay.svg',
        default: '/images/app-download/Button - Play Store.svg'
    },
    appStore: {
        black: '/images/app-download/AppStoreBlack.svg',
        white: '/images/app-download/AppStore.svg',
        default: '/images/app-download/Button - App Store.svg'
    }
};

export type AppDownloadBadgeProps = {
    appStoreLink?: AppDownloadLink;
    playStoreLink?: AppDownloadLink;
    color?: AppDownloadBadgeColor;
    className?: string;
};

export const AppDownloadBadges = ({
    appStoreLink,
    playStoreLink,
    color = 'default',
    className
}: AppDownloadBadgeProps) => {
    const tracker = useRegistrationTracker();

    const trackButtonPress = (platform: string) =>
        tracker.downloadApp(platform);

    return (
        <>
            {playStoreLink && (
                <ExternalLink
                    href={playStoreLink}
                    onClick={() => trackButtonPress('android')}
                    className={classnames(className, css.appButtons)}
                    label="Google Play store link"
                >
                    <img
                        src={PLAY_STORE_IMAGES.playStore[color]}
                        alt="Google Play icon"
                        className={css.base}
                        loading="lazy"
                    />
                </ExternalLink>
            )}
            {appStoreLink && (
                <ExternalLink
                    href={appStoreLink}
                    onClick={() => trackButtonPress('ios')}
                    className={classnames(className, css.appButtons)}
                    label="App store link"
                >
                    <img
                        src={PLAY_STORE_IMAGES.appStore[color]}
                        alt="Google Play icon"
                        className={css.base}
                        loading="lazy"
                    />
                </ExternalLink>
            )}
        </>
    );
};
