import React, { type FC } from 'react';
import { Text } from 'bb/i18n';
import { ListItem } from 'bb/ui';
import css from './footer.module.scss';

type FooterListItemProps = {
    show?: boolean;
    children?: React.ReactNode;
};

export const FooterListItem: FC<FooterListItemProps> = ({
    children,
    show = true
}) => {
    if (!show) return null;

    return (
        <ListItem>
            <Text as="span" block className={css.listItem}>
                {children}
            </Text>
        </ListItem>
    );
};
