// @ts-strict-ignore
import React, { type FC } from 'react';
import { Link } from 'bb/app/nav/Link';
import { useRouter } from 'bb/app/router';
import { ExternalLink } from 'bb/common/ExternalLink';
import { useTranslation } from 'bb/i18n';
import { FooterListItem } from './FooterListItem';

export const Impressum: FC = () => {
    const { t } = useTranslation(['footer']);

    const { routes } = useRouter();

    return (
        <>
            <FooterListItem>
                <Link
                    route={{
                        href: routes.contact.href,
                        name: 'Impressum'
                    }}
                >
                    Impressum
                </Link>
            </FooterListItem>
            <FooterListItem>
                <ExternalLink
                    href={t('footer:contactForm')}
                    label={t('footer:contact')}
                >
                    {t('footer:contact')}
                </ExternalLink>
            </FooterListItem>
        </>
    );
};
