import React from 'react';
import classnames from 'classnames';
import commonCss from 'bb/style/common.module.scss';
import { Box, type BoxProps } from 'bb/ui';

export type SectionProps = {
    withPagePadding?: boolean;
} & BoxProps<'section'>;

const SectionComponent = (
    {
        className,
        children,
        withPagePadding,
        fluid = true,
        ...restProps
    }: SectionProps,
    ref?: React.Ref<HTMLDivElement>
) => (
    <Box
        as="section"
        ref={ref}
        className={classnames(className, {
            [commonCss.pagePadding ?? '']: withPagePadding
        })}
        fluid={fluid}
        {...restProps}
    >
        {children}
    </Box>
);

export const Section = React.forwardRef(SectionComponent) as (
    props: SectionProps
) => JSX.Element;
